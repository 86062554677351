import brains1 from '../../../media/Brains/bg.png'
import brains2 from '../../../media/Brains/gif1.gif'
import brains3 from '../../../media/Brains/gif2.gif'
import brains4 from '../../../media/Brains/ficha.png'
import gifOne from '../../../media/Brains/slider-gif1.gif'
import gifTwo from '../../../media/Brains/slider-gif2.gif'
import gifThree from '../../../media/Brains/slider-gif3.gif'
import gifFour from '../../../media/Brains/slider-gif4.gif'
import gifFive from '../../../media/Brains/slider-gif5.gif'
import data1 from '../../../media/Data/01.png'
import data2 from '../../../media/Data/02.gif'
import data3 from '../../../media/Data/03.jpg'
import data4 from '../../../media/Data/04.jpg'
import data5 from '../../../media/Data/05.jpg'
import data6 from '../../../media/Data/06.jpg'
import data7 from '../../../media/Data/07.gif'
import data8 from '../../../media/Data/data5bg.jpg'
import data9 from '../../../media/Data/DATA5-popup.jpg'
import data10 from '../../../media/Data/DATA5-popup.jpg'
import data11 from '../../../media/Data/team.jpg'
import CreditImage1 from '../../../media/Data/09.png'
import CreditImage2 from '../../../media/Data/10.png'
import CreditImage3 from '../../../media/Data/11.png'
import img1 from '../../../media/Data/endgame/1.png'
import img2 from '../../../media/Data/endgame/2.png'
import img3 from '../../../media/Data/endgame/3.png'
import img4 from '../../../media/Data/endgame/4.png'
import img5 from '../../../media/Data/endgame/5.png'
import img6 from '../../../media/Data/endgame/6.png'
import img7 from '../../../media/Data/endgame/7.png'
import img8 from '../../../media/Data/endgame/8.png'
import img9 from '../../../media/Data/endgame/9.png'
import img10 from '../../../media/Data/endgame/10.png'
import img11 from '../../../media/Data/endgame/11.png'
import img12 from '../../../media/Data/endgame/12.png'
import img13 from '../../../media/Data/endgame/13.png'
import img14 from '../../../media/Data/endgame/14.png'
import img15 from '../../../media/Data/endgame/15.png'
import img16 from '../../../media/Data/endgame/16.png'
import img17 from '../../../media/Data/endgame/17.png'
import img18 from '../../../media/Data/endgame/18.png'
import img19 from '../../../media/Data/endgame/19.png'
import img20 from '../../../media/Data/endgame/20.png'
import img21 from '../../../media/Data/endgame/21.png'
import img22 from '../../../media/Data/endgame/22.png'
import img23 from '../../../media/Data/endgame/23.png'
import img24 from '../../../media/Data/endgame/24.png'
import img25 from '../../../media/Data/endgame/25.png'
import img26 from '../../../media/Data/endgame/26.png'
import img27 from '../../../media/Data/endgame/27.png'
import img28 from '../../../media/Data/endgame/28.png'
import img29 from '../../../media/Data/endgame/29.png'
import img30 from '../../../media/Data/endgame/30.png'
import img31 from '../../../media/Data/endgame/31.png'
import img32 from '../../../media/Data/endgame/32.png'
import img33 from '../../../media/Data/endgame/33.png'
import img34 from '../../../media/Data/endgame/34.png'
import img35 from '../../../media/Data/endgame/35.png'
import img36 from '../../../media/Data/endgame/36.png'
import img37 from '../../../media/Data/endgame/37.png'
import img38 from '../../../media/Data/endgame/38.png'
import img39 from '../../../media/Data/endgame/39.png'
import img40 from '../../../media/Data/endgame/40.png'
import img41 from '../../../media/Data/endgame/41.png'
import img42 from '../../../media/Data/endgame/42.png'
import img43 from '../../../media/Data/endgame/43.png'
import img44 from '../../../media/Data/endgame/44.png'
import img45 from '../../../media/Data/endgame/45.png'
import img46 from '../../../media/Data/endgame/46.png'
import img47 from '../../../media/Data/endgame/47.png'
import img48 from '../../../media/Data/endgame/48.png'
import img49 from '../../../media/Data/endgame/49.png'
import img50 from '../../../media/Data/endgame/50.png'
import img51 from '../../../media/Data/endgame/51.png'
import img52 from '../../../media/Data/endgame/52.png'
import img53 from '../../../media/Data/endgame/53.png'
import img54 from '../../../media/Data/endgame/54.png'
import img55 from '../../../media/Data/endgame/55.png'
import img56 from '../../../media/Data/endgame/56.png'
import img57 from '../../../media/Data/endgame/57.png'
import img58 from '../../../media/Data/endgame/58.png'
import img59 from '../../../media/Data/endgame/59.png'
import img60 from '../../../media/Data/endgame/60.png'
import img61 from '../../../media/Data/endgame/61.png'
import img62 from '../../../media/Data/endgame/62.png'
import img63 from '../../../media/Data/endgame/63.png'
import img64 from '../../../media/Data/endgame/64.png'
import img65 from '../../../media/Data/endgame/65.png'
import img66 from '../../../media/Data/endgame/66.png'
import img67 from '../../../media/Data/endgame/67.png'
import img68 from '../../../media/Data/endgame/68.png'
import img69 from '../../../media/Data/endgame/69.png'
import img70 from '../../../media/Data/endgame/70.png'
import img71 from '../../../media/Data/endgame/71.png'
import img72 from '../../../media/Data/endgame/72.png'
import img73 from '../../../media/Data/endgame/73.png'
import img74 from '../../../media/Data/endgame/74.png'
import img75 from '../../../media/Data/endgame/75.png'
import img76 from '../../../media/Data/endgame/76.png'
import img77 from '../../../media/Data/endgame/77.png'
import img78 from '../../../media/Data/endgame/78.png'
import img79 from '../../../media/Data/endgame/79.png'
import img80 from '../../../media/Data/endgame/80.png'
import img81 from '../../../media/Data/endgame/81.png'
import img82 from '../../../media/Data/endgame/82.png'
import img83 from '../../../media/Data/endgame/83.png'
import img84 from '../../../media/Data/endgame/84.png'
import img85 from '../../../media/Data/endgame/85.png'
import img86 from '../../../media/Data/endgame/86.png'
import img87 from '../../../media/Data/endgame/87.png'
import img88 from '../../../media/Data/endgame/88.png'
import img89 from '../../../media/Data/endgame/89.png'
import img90 from '../../../media/Data/endgame/90.png'
import img91 from '../../../media/Data/endgame/91.png'
import img92 from '../../../media/Data/endgame/92.png'
import img93 from '../../../media/Data/endgame/93.png'
import img94 from '../../../media/Data/endgame/94.png'
import img95 from '../../../media/Data/endgame/95.png'
import img96 from '../../../media/Data/endgame/96.png'
import img97 from '../../../media/Data/endgame/97.png'
import img98 from '../../../media/Data/endgame/98.png'
import img99 from '../../../media/Data/endgame/99.png'
import img100 from '../../../media/Data/endgame/100.png'
import img101 from '../../../media/Data/endgame/101.png'
import img102 from '../../../media/Data/endgame/102.png'

const imageArray = [img1,img2,img3,img4,img5,img6,img7,img8,img9,img10,img11,img12,img13,img14,img15,img16,img17,img18,img19,img20,img21,img22,img23,img24,img25,img26,img27,img28,img29,img30,img31,img32,img33,img34,img35,img36,img37,img38,img39,img40,img41,img42,img43,img44,img45,img46,img47,img48,img49,img50,img51,img52,img53,img54,img55,img56,img57,img58,img59,img60,img61,img62,img63,img64,img65,img66,img67,img68,img69,img70,img71,img72,img73,img74,img75,img76,img77,img78,img79,img80,img81,img82,img83,img84,img85,img86,img87,img88,img89,img90,img91,img92,img93,img94,img95,img96,img97,img98,img99,img100,img101,img102,brains1,brains2,brains3,brains4,data1,data2,data3,data4,data5,data6,data7,data8,data9,data10,data11,CreditImage1,CreditImage2,CreditImage3,gifOne,gifTwo,gifThree,gifFour,gifFive]

const imagePreloader = () =>{
    imageArray.forEach(image => {
        const img = new Image();
        img.src = CreditImage2
        console.log(imageArray.length)
    });
}

export default imagePreloader