import React from 'react';
import './ClickButton.css';
function ClickButton() {
  return (
    <div className='ClickButton'>
      <h1>CLICK</h1>
    </div>
  );
}

export default ClickButton;
